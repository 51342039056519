@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'ngx-toastr/toastr';

@include mat.core();

$my-app-primary: mat.define-palette(mat.$blue-grey-palette);
$my-app-accent:  mat.define-palette(mat.$blue-palette, 500, 900, A100);
$my-app-warn:    mat.define-palette(mat.$red-palette);

$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include mat.all-component-themes($my-app-theme);

.alternate-theme {
  $alternate-primary: mat.define-palette(mat.$light-blue-palette);
  $alternate-accent:  mat.define-palette(mat.$yellow-palette, 400);

  $alternate-theme: mat.define-light-theme($alternate-primary, $alternate-accent);

  @include mat.all-component-themes($alternate-theme);
}

body {
  margin: 0px;
}
.validation-error{
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: red;
}

.mat-dialog-container {
  border-radius: 15px !important;
}
